import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = { url: String }
  declare readonly urlValue: string

  connect() {
    // we preserve the URL and remove the element so cache doesn't accidentally triggers
    // a re-render
    const url = this.urlValue
    this.element.remove()

    Turbo.visit(url)
  }
}
