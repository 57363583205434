import { Controller } from "@hotwired/stimulus"
import bb, { bar, donut } from "billboard.js"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {
    type: String,
    data: Object,
    colors: Array,
    rotated: { type: Boolean, default: true },
  }
  declare readonly typeValue: string
  declare readonly dataValue: any
  declare readonly rotatedValue: boolean
  declare readonly colorsValue: string[]

  visitChartUrl(name) {
    const url = this.dataValue.data.find((o) => o.name === name).url
    window.scrollTo(0, 0)
    Turbo.visit(url, { frame: `user_watchings` })
  }

  get type() {
    switch (this.typeValue) {
      case `bar`:
        return bar()
      case `donut`:
        return donut()
    }
  }

  connect() {
    const columns = this.dataValue.data.map((o) => [o.name, o.value])

    bb.generate({
      bindto: this.element,
      data: {
        columns,
        type: this.type,
        onclick: (d) => {
          this.visitChartUrl(d.name)
        },
        labels: {
          format: (v, id, i, j) => (!this.rotatedValue ? null : `${id} - ${v}`),
        },
        onover: function () {
          this.$.main.selectAll("rect, path").style("cursor", "pointer")
        },
        onout: function () {
          this.$.main.selectAll("rect, path").style("cursor", null)
        },
      },
      onrendered: function () {
        this.$.main.selectAll(".bb-texts text").each(function (d) {
          const padding = 8
          if (this.getAttribute(`x`) > this.getComputedTextLength() + padding * 2) {
            this.style.fill = `#fff`
            this.setAttribute(`x`, padding)
          }
        })
      },
      tooltip: {
        show: false,
        grouped: false,
      },
      legend: {
        show: this.typeValue === `donut`,
        item: {
          onclick: (name) => {
            this.visitChartUrl(name)
          },
        },
      },
      color: {
        pattern: this.colorsValue,
      },
      axis: {
        rotated: this.rotatedValue,
        x: {
          show: false,
        },
        y: {
          show: false,
        },
      },
      bar: {
        width: {
          ratio: 0.9,
          max: 1000,
        },
        radius: 2,
        padding: 2,
      },
      donut: {
        padAngle: 0.01,
        label: {
          format: (value, ratio, id) => {
            return value
          },
        },
      },
    })
  }
}
