import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  touchstartX = 0
  touchstartY = 0
  touchendX = 0
  thresholdMinX = 100
  thresholdMaxY = 44

  touchstart(event: TouchEvent) {
    this.touchstartX = event.changedTouches[0].pageX
    this.touchstartY = event.changedTouches[0].pageY
  }

  touchend(event: TouchEvent) {
    let touchendY = this.touchstartY - event.changedTouches[0].pageY
    if (touchendY < 0) {
      touchendY = touchendY * -1
    }
    if (touchendY > this.thresholdMaxY) {
      return
    }
    this.touchendX = event.changedTouches[0].pageX
    if (this.touchendX < this.touchstartX && this.touchstartX - this.touchendX > this.thresholdMinX) {
      this.dispatch(`swipeLeft`)
    }
    if (this.touchendX > this.touchstartX && this.touchendX - this.touchstartX > this.thresholdMinX) {
      this.dispatch(`swipeRight`)
    }
  }
}
