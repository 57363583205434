import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [`toggle`, `menu`]
  declare readonly menuTarget: HTMLElement
  openedClasses: string[] = []
  closedClasses: string[] = [`pointer-events-none`, `opacity-0`, `scale-90`]

  connect() {
    useClickOutside(this)
  }

  open() {
    this.openedClasses.forEach(htmlClass => {
      this.menuTarget.classList.add(htmlClass)
    })
    this.closedClasses.forEach(htmlClass => {
      this.menuTarget.classList.remove(htmlClass)
    })
  }

  close() {
    this.openedClasses.forEach(htmlClass => {
      this.menuTarget.classList.remove(htmlClass)
    })
    this.closedClasses.forEach(htmlClass => {
      this.menuTarget.classList.add(htmlClass)
    })
  }

  toggle() {
    this.openedClasses.forEach(htmlClass => {
      this.menuTarget.classList.toggle(htmlClass)
    })
    this.closedClasses.forEach(htmlClass => {
      this.menuTarget.classList.toggle(htmlClass)
    })
  }

  clickOutside() {
    this.menuTarget.classList.remove(...this.openedClasses)
    this.menuTarget.classList.add(...this.closedClasses)
  }
}
