import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // TODO: ideally there would be a way to just opt out of turbo's scroll restoration
    setTimeout(() => {
      window.scrollTo(0, 0)
    })
  }
}
