import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  static values = {
    name: String,
    value: String,
  }
  declare readonly nameValue: string
  declare readonly valueValue: string

  set() {
    Cookies.set(this.nameValue, this.valueValue)
  }
}
