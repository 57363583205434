import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [`button`]
  static values = {
    title: String,
    url: String,
  }
  declare readonly buttonTarget: HTMLDivElement
  declare readonly titleValue: string
  declare readonly urlValue: string

  connect() {
    if ("share" in navigator) {
      return
    }

    this.buttonTarget.dataset[`action`] = `click->modal#show:prevent`
  }

  share(event: MouseEvent) {
    event.preventDefault()
    navigator.share({
      title: this.titleValue,
      url: this.urlValue,
    })
  }
}
