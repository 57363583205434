import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [`background`, `menu`]
  declare readonly menuTarget: HTMLDivElement
  declare readonly backgroundTarget: HTMLDivElement

  show() {
    document.body.classList.add(`overflow-hidden`)
    this.menuTarget.classList.remove(`-translate-x-40`)
    this.showBackground()
    this.backgroundTarget.classList.add(`z-40`)
    this.backgroundTarget.classList.remove(`z-30`)
  }

  hide() {
    document.body.classList.remove(`overflow-hidden`)
    this.menuTarget.classList.add(`-translate-x-40`)
    this.hideBackground()
    this.backgroundTarget.classList.remove(`z-40`)
    this.backgroundTarget.classList.add(`z-30`)
  }

  showBackground() {
    this.backgroundTarget.classList.remove(`opacity-0`, `pointer-events-none`)
  }

  hideBackground() {
    this.backgroundTarget.classList.add(`opacity-0`, `pointer-events-none`)
  }
}
