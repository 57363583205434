import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [`link`, `content`]
  declare readonly linkTargets: HTMLAnchorElement[]
  declare readonly contentTarget: HTMLDivElement
  declare readonly hasLinkTarget: boolean
  observer: IntersectionObserver

  connect() {
    this.createInteractionObserver()
  }

  createInteractionObserver() {
    if (this.observer) {
      this.observer.disconnect()
    }
    if (!this.hasLinkTarget) {
      return
    }
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      root: null,
      rootMargin: "0px",
    })
    this.observer.observe(this.linkTarget)
  }

  disconnect() {
    if (!this.observer || !this.hasLinkTarget) {
      return
    }

    this.observer.unobserve(this.linkTarget)
  }

  handleIntersect(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return
      }

      if (this.linkTarget.previousElementSibling) {
        this.linkTarget.click()
      }
      this.linkTarget.remove()
    })
  }
}
