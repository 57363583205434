import * as d3 from "d3"
import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { starsHTMLForRating } from "../utils/stars"

type Watching = {
  id: string
  watched_at: Date
  release_date: Date
  title: string
  movie_id: string
  rating: string
  note: string | null
  director_ids: string[]
  genre_ids: string[]
}

export default class extends Controller {
  json: Watching[] = []
  userWatchings!: Map<string, Watching[]>

  static targets = [`tooltip`, `chart`]
  declare readonly chartTarget: HTMLDivElement
  declare readonly hasChartTarget: boolean
  declare readonly tooltipTarget: HTMLDivElement
  activeMenuItemClasses = [`bg-gray-100`, `bg-white`]

  connect() {
    this.maybeInitCalendar()
  }

  maybeInitCalendar() {
    if (!this.hasChartTarget || this.chartTarget.querySelectorAll(`svg`).length) {
      return
    }
    this.json = JSON.parse(this.chartTarget.dataset.userWatchings).map((watching: any) => {
      watching.watched_at = new Date(watching.watched_at || watching.created_at)
      watching.release_date = new Date(watching.release_date)
      watching.rating = watching.rating.toString()
      return watching as Watching
    })
    this.userWatchings = d3.group(this.json, (watching) => watching.watched_at.toISOString().split("T")[0])
    this.generateChart()
    this.tooltipTarget.hidden = false
    window.addEventListener(`resize`, this.hideTip.bind(this))
  }

  disconnect() {
    if (!this.hasChartTarget) {
      return
    }

    window.removeEventListener(`resize`, this.hideTip.bind(this))
  }

  cellSize: number
  width: number

  generateChart() {
    let { clientWidth } = this.element
    if (clientWidth < 1024) {
      clientWidth = 1024
    }
    const cellSize = clientWidth / 56.9
    this.cellSize = cellSize
    const width = clientWidth - cellSize - 14
    this.width = width
    const height = (width - cellSize) / 5
    const day = d3.timeFormat("%w")
    const week = d3.timeFormat("%U")
    const format = d3.timeFormat("%Y-%m-%d")

    const monthPath = function (t0: Date) {
      const t1 = new Date(t0.getFullYear(), t0.getMonth() + 1, 0)
      const d0 = +day(t0)
      const w0 = +week(t0)
      const d1 = +day(t1)
      const w1 = +week(t1)
      return `M${(w0 + 1) * cellSize},${d0 * cellSize}H${w0 * cellSize}V${7 * cellSize}H${w1 * cellSize}V${
        (d1 + 1) * cellSize
      }H${(w1 + 1) * cellSize}V${0}H${(w0 + 1) * cellSize}Z`
    }

    const startYear = this.json[0] ? this.json[0].watched_at.getFullYear() : new Date().getFullYear()

    const svg = d3
      .select("#chart")
      .selectAll("svg")
      .data(d3.range(startYear, new Date().getFullYear() + 1).reverse())
      .enter()
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("class", "mx-auto my-2")
      .append("g")
      .attr("transform", `translate(${cellSize},${cellSize * 2})`)

    svg
      .append("text")
      .attr("x", -17)
      .attr("y", -22)
      .attr(`class`, `text-md font-bold italic text-gray-400`)
      .text(String)

    svg
      .append("g")
      .attr("text-anchor", "middle")
      .selectAll("text")
      .data(d3.range(7))
      .join("text")
      .attr("x", -12)
      .attr(`class`, `font-light text-sm text-gray-400`)
      .attr("y", (i) => (i + 0.8) * cellSize)
      .text((i) => "SMTWTFS"[i])

    this.days = svg
      .selectAll("rect")
      .data((d) => d3.timeDays(new Date(d, 0, 1), new Date(d + 1, 0, 1)))
      .enter()
      .append("rect")
      .attr("class", `text-gray-400 stroke-current transition duration-150 fill-white`)
      .attr("width", cellSize)
      .attr("height", cellSize)
      .attr("x", (d) => week(d) * cellSize)
      .attr("y", (d) => day(d) * cellSize)
      .attr(`data-day`, format)
      .datum(format)

    const month = svg
      .append(`g`)
      .selectAll(`g`)
      .data((d) => d3.timeMonths(new Date(d, 0, 1), new Date(d + 1, 0, 1)))
      .join(`g`)

    month
      .append("path")
      .attr("class", `stroke-2 text-black stroke-current`)
      .style(`fill`, `none`)
      .attr("d", monthPath)

    month
      .append(`text`)
      .attr(`y`, -5)
      .attr("x", (d) => d3.utcMonday.count(d3.utcYear(d), d3.utcMonday.ceil(d)) * cellSize + 2)
      .attr(`class`, `font-light text-sm text-gray-400 uppercase`)
      .text(d3.utcFormat("%b"))

    this.renderDays()
  }

  showTip(event: MouseEvent) {
    const element = event.currentTarget as HTMLDivElement
    if (!element.classList.contains(`stroke-violet-600`)) {
      return
    }
    this.days.classed(`fill-pink-600`, false)
    const dateString = element.dataset.day
    const currentUser = !!this.element.attributes[`data-current-user`]
    const watchingsHTML = this.currentWatchings(dateString)
      .map((watching: Watching) => {
        // TODO: line-clamp and click to reveal
        const note = watching.note?.length ? `<div class="text-xs">${watching.note}</div>` : ``
        const editLink = currentUser
          ? `<div class="self-center text-xs text-gray-400 ml-1 cursor-pointer"
            data-action="click->modal#show:prevent"
          data-modal-url="/watched/${watching.id}/edit">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
          </svg>
          </div>`
          : ``
        return `
            <div class="py-1">
              <div class="flex">
                <a class="flex-grow self-center pr-2 text-lg" data-turbo-frame="_top" href="/movies/${
                  watching.movie_id
                }">${watching.title}</a>
                <div class="flex-none flex">
                  <a class="self-center text-yellow-500" data-turbo-frame="_top" href="/movies/${
                    watching.movie_id
                  }">${starsHTMLForRating(parseInt(watching.rating))}</a>
                  ${editLink}
                </div>
              </div>
              ${note}
            </div>
          `
      })
      .join(``)
    const formattedDateString = new Date(dateString).toLocaleString(`UTC`, {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    })
    this.tooltipTarget.innerHTML = `<div class="font-semibold italic text-md pb-2">${formattedDateString}</div>${watchingsHTML}`
    this.tooltipTarget.classList.remove(`pointer-events-none`, `opacity-0`)
    this.tooltipTarget.classList.add(`opacity-90`)
    const boundingBox = element.getBoundingClientRect()
    this.tooltipTarget.style.top = `${boundingBox.top + window.scrollY}px`
    if (event.pageX > this.width * 0.9) {
      this.tooltipTarget.style.left = null
      this.tooltipTarget.style.right = `${window.innerWidth - boundingBox.right + this.cellSize / 2 - 1}px`
    } else {
      this.tooltipTarget.style.right = null
      this.tooltipTarget.style.left = `${boundingBox.right}px`
    }
  }

  hideTip() {
    if (!this.hasChartTarget) {
      return
    }
    this.days.classed(`fill-pink-600`, false)
    this.tooltipTarget.classList.remove(`opacity-90`)
    this.tooltipTarget.classList.add(`pointer-events-none`, `opacity-0`)
  }

  currentWatchings = (day) => {
    return this.userWatchings.get(day) || []
  }

  renderDays() {
    if (!this.hasChartTarget) {
      return
    }

    this.days
      .filter((day) => this.currentWatchings(day).length)
      .attr(
        "class",
        `text-gray-400 stroke-current transition duration-150 fill-violet-400 stroke-violet-600`,
      )
      .attr(`data-action`, `mouseover->user-watchings#showTip click->user-watchings#showTip`)
  }
}
