import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [`toggle`]
  static classes = [`toggle`]
  declare readonly toggleTarget: HTMLDivElement
  declare readonly toggleClasses: string[]

  toggle() {
    this.toggleClasses.forEach((string) => {
      this.toggleTarget.classList.toggle(string)
    })
  }
}
