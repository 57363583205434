import "@hotwired/turbo-rails"
import debounced from "debounced"
import jstz from "jstz"
import Bugsnag from "@bugsnag/js"

Bugsnag.start({ apiKey: "9bb04d288d447c7ada382814ae85b868" })
const setCookie = (name: string, value: string) => {
  var expires = new Date()
  expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000)
  document.cookie = name + "=" + value + ";expires=" + expires.toUTCString()
}
const timezone = jstz.determine()
setCookie("timezone", timezone.name())

import { Application, defaultSchema } from "@hotwired/stimulus"

const customSchema = {
  ...defaultSchema,
  keyMappings: { ...defaultSchema.keyMappings, slash: "/" },
}

import { registerControllers } from "stimulus-vite-helpers"
const application = Application.start(document.documentElement, customSchema)
// @ts-ignore
const controllers = import.meta.glob("../controllers/*.ts", { eager: true })
registerControllers(application, controllers)

debounced.initialize([`input`], { wait: 300 })
debounced.initialize([`scroll`], { wait: 40 })

window.addEventListener("load", () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/service_worker.js")
  }
})
