import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [`note`]
  declare readonly noteTarget: HTMLDivElement

  revealNote(event: MouseEvent) {
    const div = event.target as HTMLDivElement
    div.hidden = true
    this.noteTarget.classList.remove(`hidden`)
    this.noteTarget.querySelector(`textarea`).focus()
  }
}
