export const starsHTMLForRating = (rating: number) => {
  return `<div class="relative">
  <div class="absolute h-full flex text-clip overflow-hidden" style="width:${rating * 10.0}%;">
  ★★★★★
  </div>
  <div class="flex text-gray-300">
  ★★★★★
  </div>
  </div>`
}
