import { Controller } from "@hotwired/stimulus"
import mapboxgl, { Map, Popup } from "mapbox-gl"
import { Turbo } from "@hotwired/turbo-rails"

mapboxgl.accessToken =
  "pk.eyJ1IjoidGhlcGFsbWljdmV0IiwiYSI6ImNraTdzMWIyZDBuYWUyeW40MzFjbjRjc2YifQ.GwQ-OcfWWZykLHaBqGnyLA"

function colorChannelMixer(colorChannelA: number, colorChannelB: number, amountToMix: number) {
  var channelA = colorChannelA * amountToMix
  var channelB = colorChannelB * (1 - amountToMix)
  return Math.round(channelA + channelB)
}

function colorMixer(rgbA: [number, number, number], rgbB: [number, number, number], amountToMix: number) {
  var r = colorChannelMixer(rgbA[0], rgbB[0], amountToMix)
  var g = colorChannelMixer(rgbA[1], rgbB[1], amountToMix)
  var b = colorChannelMixer(rgbA[2], rgbB[2], amountToMix)
  return "rgb(" + r + "," + g + "," + b + ")"
}

export default class extends Controller<HTMLDivElement> {
  static values = {
    countryTotals: Object,
    listPath: String,
  }
  declare readonly countryTotalsValue: { [[string, string]]: number }
  declare readonly listPathValue: string
  resizer: ResizeObserver

  map: Map
  connect() {
    this.map = new Map({
      container: this.element,
      style: `mapbox://styles/mapbox/light-v11`,
      projection: {
        name: `naturalEarth`,
      },
      center: [0, 0],
      zoom: this.zoom,
      maxZoom: 4,
      dragPan: false,
      boxZoom: false,
      scrollZoom: false,
      doubleClickZoom: false,
      attributionControl: false,
    })

    const keys = Object.keys(this.countryTotalsValue)

    this.map.on(`load`, () => {
      this.map.addSource(`country-boundaries`, {
        type: "vector",
        url: "mapbox://mapbox.country-boundaries-v1",
      })

      this.map.addLayer({
        id: "country-boundaries",
        source: `country-boundaries`,
        "source-layer": "country_boundaries",
        type: "fill",
        paint: {
          "fill-color": ["string", ["feature-state", "fillColor"], "#ffffff"],
          "fill-opacity": ["number", ["feature-state", "fillOpacity"], 0],
        },
        filter: [
          "all",
          ["in", ["get", "iso_3166_1"], ["literal", keys]],
          ["==", ["get", "disputed"], "false"],
          ["any", ["==", "all", ["get", "worldview"]], ["in", "US", ["get", "worldview"]]],
        ],
      })

      const popup = new Popup({
        closeButton: false,
        closeOnClick: false,
      })

      this.map.on("mousemove", "country-boundaries", (e) => {
        if (e.features.length > 0) {
          const feature = e.features[0]
          this.map.getCanvas().style.cursor = "pointer"
          const html = `<div><div>${feature.properties.name}</div><div>${
            this.countryTotalsValue[feature.properties.iso_3166_1]
          } watchings</div>`
          popup.setLngLat(e.lngLat).setHTML(html).addTo(this.map)
        }
      })

      this.map.on("mouseleave", "country-boundaries", () => {
        this.map.getCanvas().style.cursor = ""
        popup.remove()
      })

      this.map.on("click", "country-boundaries", (e) => {
        const feature = e.features[0]
        if (!feature) {
          return
        }

        window.scrollTo(0, 0)
        Turbo.visit(
          `${this.listPathValue}&chart=production_country&value=${feature.properties.iso_3166_1}`,
          { frame: `user_watchings` },
        )
      })
    })

    var rendered = false

    this.map.on(`idle`, () => {
      const source = this.map.getSource(`country-boundaries`)

      // @ts-ignore
      if (!source.loaded() || rendered) {
        return
      }

      const size = keys.length
      const features = this.map.querySourceFeatures(`country-boundaries`, {
        sourceLayer: `country_boundaries`,
      })
      keys.forEach((code, index) => {
        const feature = features.find((feature) => feature.properties.iso_3166_1 === code)
        if (feature) {
          const fillOpacity = ((size - index) / size) * 0.67 + 0.1
          const fillColor = colorMixer([192, 38, 211], [232, 121, 249], 1 - index / size)
          this.map.setFeatureState(
            { id: feature.id, source: `country-boundaries`, sourceLayer: `country_boundaries` },
            { fillOpacity, fillColor },
          )
        }
      })
      rendered = true
    })

    this.resizer = new ResizeObserver(this.resize.bind(this))
    this.resizer.observe(this.element)
  }

  resize() {
    this.map.setZoom(this.zoom)
  }

  get zoom() {
    if (window.innerWidth < 640) {
      return 0.01
    } else {
      return (window.innerWidth / 1024) * 1.0
    }
  }

  disconnect() {
    this.resizer.unobserve(this.element)
    this.map.remove()
  }
}
