import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLInputElement>{
  connect() {
    const date = new Date()
    const value = date.toLocaleDateString(`sv-SE`).replaceAll("/", "-");
    this.element.value ||= value
    this.element.max = value
  }
}
