import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = [`draggable`, `number`, `item`]
  static values = {
    sortUrl: String,
  }
  declare readonly draggableTargets: HTMLDivElement[]
  declare readonly numberTargets: HTMLDivElement[]
  declare readonly itemTargets: HTMLDivElement[]
  declare readonly sortUrlValue: string
  draggingClasses = [`opacity-50`]
  droppableClasses = [`bg-gray-100`]

  dragstart(event: DragEvent) {
    if (!event.dataTransfer) {
      return
    }

    const el = this.getDraggable(event)
    const item = this.itemTargets.find((target) => el.contains(target))
    item.classList.add(...this.draggingClasses)
    event.dataTransfer.setData(`text/plain`, el.id)
    event.dataTransfer.effectAllowed = `move`
  }

  dragover(event: DragEvent) {
    if (!event.dataTransfer) {
      return
    }

    const el = this.getDraggable(event)
    const item = this.itemTargets.find((target) => el.contains(target))
    item.classList.add(...this.droppableClasses)
    const isDragging = event.dataTransfer.types.includes(`text/plain`)
    if (isDragging) {
      event.preventDefault()
    }
    event.dataTransfer.dropEffect = `move`
  }

  elIsNotDragging(el: HTMLElement) {
    return el && !el.classList.contains(this.draggingClasses[0])
  }

  dragleave(event: DragEvent) {
    const el = this.getDraggable(event)

    if (this.elIsNotDragging(el)) {
      const item = this.itemTargets.find((target) => el.contains(target))
      item.classList.remove(...this.droppableClasses)
    }
    event.preventDefault()
  }

  dragenter(event: DragEvent) {
    event.preventDefault()
  }

  dragend(event: DragEvent) {
    event.preventDefault()
    this.deselectDragged(event)
  }

  draggedTarget(id: string) {
    return this.draggableTargets.find((el) => el.id === id)
  }

  deselectDragged(event: DragEvent) {
    if (!event.dataTransfer) {
      return
    }

    const id = event.dataTransfer.getData(`text/plain`)
    const draggedTarget = this.draggedTarget(id) || document.getElementById(id)
    if (!draggedTarget) {
      return
    }
    const item = this.itemTargets.find((target) => draggedTarget.contains(target))
    item.classList.remove(...this.draggingClasses)
  }

  drop(event: DragEvent) {
    event.preventDefault()
    if (!event.dataTransfer) {
      return
    }

    const dropTarget = this.getDraggable(event)
    const id = event.dataTransfer.getData(`text/plain`)
    const draggedTarget = this.draggedTarget(id)
    const item = this.itemTargets.find((target) => dropTarget.contains(target))
    item.classList.remove(...this.droppableClasses)
    this.deselectDragged(event)
    if (!draggedTarget || dropTarget === draggedTarget) {
      return
    }
    const positionComparison = dropTarget.compareDocumentPosition(draggedTarget)
    if (positionComparison & 4) {
      dropTarget.insertAdjacentElement(`beforebegin`, draggedTarget)
    } else if (positionComparison & 2) {
      dropTarget.insertAdjacentElement(`afterend`, draggedTarget)
    }
    this.performSortRequest()
  }

  async performSortRequest() {
    const params = { ids: this.draggableTargets.map((el) => el.id) }
    await post(this.sortUrlValue, { body: JSON.stringify(params) })
    this.numberTargets.forEach((target, index) => {
      target.innerText = (index + 1).toString()
    })
  }

  getDraggable(event: Event) {
    let el = event.target as HTMLElement | null
    while (true) {
      if (!el || el.draggable) {
        break
      }
      el = el.parentElement
    }
    return el
  }
}
