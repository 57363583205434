import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [`tip`]
  declare readonly tipTarget: HTMLDivElement

  show() {
    this.tipTarget.classList.remove(`opacity-0`)
    this.tipTarget.classList.add(`opacity-90`)
  }

  hide() {
    this.tipTarget.classList.remove(`opacity-90`)
    this.tipTarget.classList.add(`opacity-0`)
  }
}
