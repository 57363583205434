import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed"
    platform: string
  }>
  prompt(): Promise<void>
}

export default class extends Controller<HTMLDivElement> {
  static targets = [`button`]
  deferredPrompt: null | BeforeInstallPromptEvent = null
  buttonTarget: HTMLButtonElement

  connect() {
    window.addEventListener("beforeinstallprompt", this.beforeinstallprompt.bind(this))
    window.addEventListener("appinstalled", this.dismiss.bind(this))
  }

  disconnect() {
    window.removeEventListener("beforeinstallprompt", this.beforeinstallprompt.bind(this))
    window.removeEventListener("appinstalled", this.dismiss.bind(this))
  }

  beforeinstallprompt(e: BeforeInstallPromptEvent) {
    if (Cookies.get(`dismiss_install`)) {
      return
    }
    this.deferredPrompt = e
    this.element.hidden = false
  }

  dismiss() {
    this.deferredPrompt = null
    this.element.hidden = true
  }

  async prompt() {
    this.element.hidden = true
    this.deferredPrompt.prompt()
    await this.deferredPrompt.userChoice
    this.deferredPrompt = null
  }
}
