import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [`minRange`, `maxRange`, `minNumber`, `maxNumber`]
  declare readonly minRangeTarget: HTMLInputElement
  declare readonly maxRangeTarget: HTMLInputElement
  declare readonly minNumberTarget: HTMLInputElement
  declare readonly maxNumberTarget: HTMLInputElement
  sliderColor = `#C6C6C6`
  rangeColor = `#9334e9`

  connect() {
    this.fillSlider()
  }

  getParsed(minTarget: HTMLInputElement, maxTarget: HTMLInputElement) {
    const min = parseInt(minTarget.value, 10)
    const max = parseInt(maxTarget.value, 10)
    return [min, max]
  }

  minNumberChanged() {
    const [min, max] = this.getParsed(this.minNumberTarget, this.maxNumberTarget)
    if (min > max) {
      this.minRangeTarget.value = max.toString()
      this.minNumberTarget.value = max.toString()
    } else {
      this.minRangeTarget.value = min.toString()
    }
    this.fillSlider()
  }

  maxNumberChanged() {
    const [min, max] = this.getParsed(this.minNumberTarget, this.maxNumberTarget)
    if (min <= max) {
      this.maxRangeTarget.value = max.toString()
      this.maxNumberTarget.value = max.toString()
    } else {
      this.maxRangeTarget.value = min.toString()
    }
    this.fillSlider()
  }

  minRangeChanged() {
    const [min, max] = this.getParsed(this.minRangeTarget, this.maxRangeTarget)
    if (min > max) {
      this.minRangeTarget.value = max.toString()
      this.minNumberTarget.value = max.toString()
    } else {
      this.minNumberTarget.value = min.toString()
    }
    this.fillSlider()
  }

  maxRangeChanged() {
    const [min, max] = this.getParsed(this.minRangeTarget, this.maxRangeTarget)
    if (min <= max) {
      this.maxRangeTarget.value = max.toString()
      this.maxNumberTarget.value = max.toString()
    } else {
      this.maxRangeTarget.value = min.toString()
    }
    this.fillSlider()
  }

  fillSlider() {
    const rangeDistance = this.maxRangeTarget.max - this.maxRangeTarget.min
    const fromPosition = this.minRangeTarget.value - this.maxRangeTarget.min
    const toPosition = this.maxRangeTarget.value - this.maxRangeTarget.min
    this.maxRangeTarget.style.background = `linear-gradient(
      to right,
      ${this.sliderColor} 0%,
      ${this.sliderColor} ${(fromPosition / rangeDistance) * 100}%,
      ${this.rangeColor} ${(fromPosition / rangeDistance) * 100}%,
      ${this.rangeColor} ${(toPosition / rangeDistance) * 100}%,
      ${this.sliderColor} ${(toPosition / rangeDistance) * 100}%,
      ${this.sliderColor} 100%)`
  }
}
