import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.hide()
    }, 5000)
  }

  hide() {
    this.element.hidden = true
  }
}
