import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["query", "activity", "list"]
  static values = {
    id: String,
  }
  declare readonly queryTarget: HTMLInputElement
  declare readonly activityTarget: HTMLInputElement
  declare readonly listTarget: HTMLInputElement
  declare readonly idValue: string
  declare readonly hasListTarget: boolean
  selected = null
  clicking = false

  beforePerform(event: { target: HTMLElement }) {
    if (!event.target?.id?.match("search")) {
      return
    }
    window.scrollTo(0, 0)
    this.activityTarget.classList.remove(`hidden`)
    this.activityTarget.classList.add(`flex`)
  }

  perform(event?: Event) {
    event?.preventDefault()
    Turbo.visit(`/search/${this.idValue}?q=${encodeURIComponent(this.queryTarget.value)}`, {
      frame: `${this.idValue}-search-results`,
    })
  }

  afterPerform() {
    this.activityTarget.classList.add(`hidden`)
    this.activityTarget.classList.remove(`flex`)
    if (!this.hasListTarget) {
      this.selected = null
    } else {
      const firstLink = this.listTarget.children[0]
      if (firstLink) {
        this.selectResult(firstLink as HTMLDivElement)
      }
    }
  }

  selectResult(element: HTMLDivElement) {
    if (window.innerWidth >= 640) {
      if (this.selected) {
        this.selected.classList.remove(`bg-amber-100/70`)
        this.selected.classList.add(`bg-white`)
      }
      if (element) {
        element.classList.remove(`bg-white`)
        element.classList.add(`bg-amber-100/70`)
      }
    }
    this.selected = element
  }

  mousedown() {
    this.clicking = true
  }

  hideResults() {
    if (this.clicking) {
      this.clicking = false
      return
    }

    this.listTarget.classList.add(`hidden`)
  }

  showResults() {
    this.listTarget.classList.remove(`hidden`)
  }

  walkList(e: Event, siblingMethod: string) {
    e.preventDefault()
    if (!this.selected) {
      return
    }

    let div = this.selected[siblingMethod]
    if (div) {
      this.selectResult(div)
    }
  }

  nextSelection(e: Event) {
    this.walkList(e, `nextElementSibling`)
  }

  previousSelection(e: Event) {
    this.walkList(e, `previousElementSibling`)
  }

  maybeFocus(e: KeyboardEvent) {
    if (document.activeElement !== this.queryTarget && document.activeElement.nodeName !== `INPUT`) {
      this.queryTarget.focus()
      e.preventDefault()
    }
  }

  maybeSpecialCommand(e: KeyboardEvent) {
    if (e.key === `Enter` && this.selected) {
      this.selected.dispatchEvent(new Event(`mousedown`))
      this.selected.click()
      this.selected.dispatchEvent(new Event(`mouseup`))
    }
    if (e.key === `n` && e.ctrlKey) {
      this.nextSelection(e)
    }
    if (e.key === `p` && e.ctrlKey) {
      this.previousSelection(e)
    }
    switch (e.key) {
      case `ArrowDown`:
        this.nextSelection(e)
        break
      case `ArrowUp`:
        this.previousSelection(e)
        break
    }
  }

  connect() {
    super.connect()
    this.queryTarget.value = ``
  }
}
