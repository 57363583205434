import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [`input`, `flash`]
  declare readonly inputTarget: HTMLInputElement
  declare readonly flashTarget: HTMLDivElement

  copy(event: MouseEvent) {
    event.preventDefault()
    this.inputTarget.select()
    document.execCommand("copy")
    this.flashTarget.hidden = false
    setTimeout(() => {
      this.flashTarget.hidden = true
    }, 2000)
  }
}
