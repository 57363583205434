import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { post } from "@rails/request.js"

export default class extends Controller {
  static values = {
    url: String,
    method: {
      type: String,
      default: `GET`,
    },
  }
  declare readonly urlValue: string
  declare readonly methodValue: string

  tapDownTime = 0

  captureTapDown() {
    this.tapDownTime = Date.now()
  }

  goTo(event: { metaKey: boolean; preventDefault: () => void; target: any }) {
    if (Date.now() - this.tapDownTime > 300) {
      event.preventDefault()
      return
    }
    let el = event.target
    while (true) {
      if (el === this.element) {
        const url = this.urlValue
        switch (this.methodValue) {
          case `GET`:
            if (event.metaKey) {
              window.open(url, "_blank").focus()
            } else {
              Turbo.visit(url)
            }
            break
          case `POST`:
            post(url)
            break
        }

        break
      }
      if (
        [`a`, `label`, `button`].includes(el.nodeName.toLowerCase()) ||
        el.attributes.onclick?.value ||
        el.attributes[`data-action`]
      ) {
        break
      }
      el = el.parentElement
    }
  }
}
