import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = [`container`, `content`, `successIndication`]
  declare readonly containerTarget: HTMLDivElement
  declare readonly contentTarget: HTMLDivElement
  declare readonly successIndicationTarget: HTMLDivElement
  declare readonly hasSuccessIndicationTarget: boolean

  async show(event: Event) {
    const div = event.currentTarget as HTMLDivElement
    const { modalUrl, modalElementId } = div.dataset
    let text: string
    if (modalUrl) {
      const response = await fetch(modalUrl, { headers: { "X-Requested-With": `fetch` } })
      text = await response.text()
    } else {
      text = this.element.querySelector(`#${modalElementId}`).innerHTML
    }
    this.reveal(text)
  }

  reveal(content: string) {
    document.body.classList.add(`overflow-hidden`)
    this.containerTarget.hidden = false
    this.contentTarget.innerHTML = content
    window.requestAnimationFrame(() => {
      this.contentTarget.classList.remove(`scale-50`, `opacity-0`)
      this.contentTarget.classList.add(`scale-100`, `opacity-100`)
      const input = this.contentTarget.querySelector(`input[autofocus]`) as HTMLInputElement
      if (input) {
        input.focus()
        input.select()
      }
    })
  }

  watchTrailer(event: Event) {
    const { youtubeTrailerKey } = (event.currentTarget as HTMLDivElement).dataset
    const trailerUrl = `https://www.youtube.com/embed/${youtubeTrailerKey}?enablejsapi=1&theme=dark`
    this.reveal(`
      <div class="w-screen -ml-3 max-w-7xl p-4">
        <div class="m-auto">
          <iframe src="${trailerUrl}" frameborder="0" allowfullscreen class="w-full aspect-video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
          </iframe>
        </div>
      </div>`)
  }

  maybeShowKeyboardShortcuts(e: KeyboardEvent) {
    if (e.key !== `?`) {
      return
    }
    if (document.activeElement.nodeName === `INPUT`) {
      return
    }

    this.reveal(document.getElementById(`keyboard-shortcuts`).innerHTML)
  }

  close() {
    document.body.classList.remove(`overflow-hidden`)
    this.contentTarget.classList.remove(`scale-100`, `opacity-100`)
    this.contentTarget.classList.add(`scale-50`, `opacity-0`)
    this.containerTarget.hidden = true
    this.contentTarget.innerHTML = ``
  }

  autoClose(e: any) {
    if (e.detail.fetchResponse.response.status !== 200) {
      return
    }
    if (this.hasSuccessIndicationTarget) {
      window.requestAnimationFrame(() => {
        this.successIndicationTarget.classList.remove(`scale-50`)
        this.successIndicationTarget.classList.add(`scale-100`)
      })
    }
    setTimeout(() => {
      this.close()
      Turbo.visit(window.location.toString(), { action: "replace" })
    }, 1000)
  }
}
