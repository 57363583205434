import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLFormElement> {
  static targets = [`success`, `checkbox`, `minInput`, `maxInput`]
  declare readonly hasSuccessTarget: boolean
  declare readonly successTarget: HTMLDivElement
  declare readonly checkboxTargets: HTMLInputElement[]
  declare readonly minInputTargets: HTMLInputElement[]
  declare readonly maxInputTargets: HTMLInputElement[]

  submit() {
    this.element.requestSubmit()
  }

  showSuccess() {
    this.successTarget.ontransitionend = () => {
      setTimeout(() => {
        this.successTarget.ontransitionend = null
        this.successTarget.classList.remove(`opacity-100`)
        this.successTarget.classList.add(`opacity-0`)
      }, 2000)
    }
    this.successTarget.classList.remove(`opacity-0`)
    this.successTarget.classList.add(`opacity-100`)
  }

  reset() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.minInputTargets.forEach((input) => {
      input.value = input.min
    })
    this.maxInputTargets.forEach((input) => {
      input.value = input.max
    })
    this.submit()
  }
}
