import { Controller } from "@hotwired/stimulus"
import { starsHTMLForRating } from "../utils/stars"

export default class extends Controller {
  static targets = ["slider", "value"]
  declare readonly valueTarget: HTMLDivElement
  declare readonly sliderTarget: HTMLInputElement

  update() {
    const rating = parseInt(this.sliderTarget.value)
    const starHTML = starsHTMLForRating(rating)
    this.valueTarget.innerHTML = starHTML
  }

  connect() {
    this.update()
  }
}
