import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [`hideable`, `content`]
  declare readonly contentTarget: HTMLElement
  declare readonly hideableTarget: HTMLElement

  show() {
    this.hideableTarget.classList.add(`hidden`)
    this.contentTarget.hidden = false
    const input = this.contentTarget.querySelector(`input[type="text"]`) as HTMLInputElement
    input?.focus()
  }

  hide() {
    this.hideableTarget.classList.remove(`hidden`)
    this.contentTarget.hidden = true
  }
}
