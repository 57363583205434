import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLAnchorElement> {
  href: string | null = null

  disable() {
    this.href = this.element.href
    setTimeout(() => {
      this.element.href = "javascript:"
    }, 0)
  }

  enable() {
    this.element.href = this.href
  }
}
